import React from 'react'
import Layout from 'components/layout'
import { Helmet } from 'react-helmet'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import { graphql } from 'gatsby'
import Content from 'components/category/content'

const items = [
  {
    link: '/stihl/hedge-trimmers/professional-hedge-trimmers/',
    imageId: 'professionalHedgeTrimmersImage',
    name: 'Professional Hedge Trimmers',
    alt: 'STIHL Professional Hedge Trimmers',
  },
  {
    link: '/stihl/hedge-trimmers/battery-hedge-trimmers/',
    imageId: 'batteryHedgeTrimmersImage',
    name: 'Battery Hedge Trimmers',
    alt: 'STIHL Battery Hedge Trimmers',
  },
  {
    link: '/stihl/hedge-trimmers/homeowner-hedge-trimmers/',
    imageId: 'homeownerHedgeTrimmersImage',
    name: 'Homeowner Hedge Trimmers',
    alt: 'STIHL Homeowner Hedge Trimmers',
  },
  {
    link: '/stihl/hedge-trimmers/electric-hedge-trimmers/',
    imageId: 'electricHedgeTrimmersImage',
    name: 'Electric Hedge Trimmers',
    alt: 'STIHL Electric Hedge Trimmers',
  },
]

const HedgeTrimmersPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const floaterItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Hedge Trimmers | Hutson Inc</title>
        <meta
          name='description'
          content='Save time and energy while trimming your hedges and other shrubbery with STIHL battery and gas-powered hedge trimmers.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Hedge Trimmers',
                'item': 'https://www.hutsoninc.com/stihl/hedge-trimmers/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='STIHL Hedge Trimmers' />

      <Content>
        <Floater items={floaterItems} />

        <Promos data={promos} type='STIHL Hedge Trimmer' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query hedgeTrimmersStihlQuery($category: String = "hedge-trimmers") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "stihl/hedge-trimmers/hedge-trimmers-bg.jpg" }) {
      ...FullWidthImage
    }
    professionalHedgeTrimmersImage: file(
      relativePath: { eq: "stihl/hedge-trimmers/professional-hedge-trimmers.jpg" }
    ) {
      ...FloatingGridImage
    }
    batteryHedgeTrimmersImage: file(
      relativePath: { eq: "stihl/hedge-trimmers/battery-hedge-trimmers.jpg" }
    ) {
      ...FloatingGridImage
    }
    homeownerHedgeTrimmersImage: file(
      relativePath: { eq: "stihl/hedge-trimmers/homeowner-hedge-trimmers.jpg" }
    ) {
      ...FloatingGridImage
    }
    electricHedgeTrimmersImage: file(
      relativePath: { eq: "stihl/hedge-trimmers/electric-hedge-trimmers.jpg" }
    ) {
      ...FloatingGridImage
    }
  }
`

export default HedgeTrimmersPage
